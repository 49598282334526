import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'vite-plugin-laravel/inertia';
import i18n from './i18n';
import { createRoute } from './routes';
import { Ziggy } from './routes/ziggy';
import * as Sentry from '@sentry/vue';
import Countly from 'countly-sdk-web';
import store from './store/store';

createInertiaApp({
    title: (title) => `${title} | ${import.meta.env.WEB_NAME}`,
    progress: {
        color: 'var(--primary)',
    },
    resolve: (name) => resolvePageComponent(name, import.meta.glob('./pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        app.use(plugin);

        app.use(i18n);

        app.use(createRoute, Ziggy);

        app.use(store);

        if (import.meta.env.WEB_SENTRY_DSN && import.meta.env.WEB_ENV) {
            Sentry.init({
                app,

                dsn: import.meta.env.WEB_SENTRY_DSN,
                environment: import.meta.env.WEB_ENV,

                replaysSessionSampleRate: import.meta.env.WEB_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
                    ? parseFloat(import.meta.env.WEB_SENTRY_REPLAYS_SESSION_SAMPLE_RATE)
                    : 0.0,
                replaysOnErrorSampleRate: import.meta.env.WEB_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
                    ? parseFloat(import.meta.env.WEB_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE)
                    : 0.0,

                integrations: [new Sentry.Replay()],
            });
        }

        // @ts-ignore
        const acceptLanguage = window.navigator.language || window.navigator.userLanguage;
        i18n.global.locale.value = acceptLanguage.startsWith('en') ? 'en' : 'nl';

        app.mount(el);

        return app;
    },
});

if (import.meta.env.WEB_COUNTLY_URL && import.meta.env.WEB_COUNTLY_KEY) {
    Countly.app_key = import.meta.env.WEB_COUNTLY_URL;
    Countly.url = import.meta.env.WEB_COUNTLY_KEY;

    Countly.q.push(['track_sessions']);
    Countly.q.push(['track_pageview']);
    Countly.q.push(['track_clicks']);
    Countly.q.push(['track_scrolls']);

    Countly.init();
}
