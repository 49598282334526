import { ActionContext } from 'vuex';
import { Toast } from '../../data/Toast';

export interface ToastsState {
    toasts: Toast[];
}

export default {
    namespaced: true,

    state: {
        toasts: [],
    } as ToastsState,

    mutations: {
        ADD(state: ToastsState, toast: Toast) {
            toast.created_at = new Date().toISOString();

            state.toasts.push(toast);
        },

        REMOVE(state: ToastsState, toast: Toast) {
            const toasts = state.toasts;

            const index = toasts.findIndex((t) => {
                return t.created_at === toast.created_at;
            });

            if (index > -1) {
                state.toasts.splice(index, 1);
            }
        },
    },

    actions: {
        add({ commit }: ActionContext<ToastsState, any>, payload: { toast: Toast }) {
            commit('ADD', payload.toast);
        },

        remove({ commit }: ActionContext<ToastsState, any>, payload: { toast: Toast }) {
            commit('REMOVE', payload.toast);
        },
    },
};
