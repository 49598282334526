import { createI18n } from 'vue-i18n';
import nl from './locales/nl.json';
import en from './locales/en.json';

const i18n = createI18n({
    legacy: false,
    locale: 'nl',
    fallbackLocale: 'nl',
    messages: {
        nl: nl,
        en: en,
    },
    datetimeFormats: {
        nl: {
            short_month: {
                month: 'short',
            },
            day: {
                day: 'numeric',
            },
            hour_minute: {
                hour: 'numeric',
                minute: 'numeric',
            },
        },
    },
});

export default i18n;
