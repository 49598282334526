import { App } from 'vue';
import route, { Config, RouteParam, RouteParamsWithQueryOverload, Router } from 'ziggy-js';
import { useRouteKey } from './useRoute';

export const createRoute = {
    install: (app: App, options: Config) => {
        const routeFunction = (
            name: string,
            params?: RouteParamsWithQueryOverload | RouteParam,
            absolute?: boolean,
            config: Config = options
        ): Router | string => {
            return route(name, params, absolute, config);
        };

        app.provide(useRouteKey, routeFunction);
    },
};
