import { createStore } from 'vuex';
import toastsModule, { ToastsState } from './modules/toasts';

interface RootState {
    toasts: ToastsState;
}

const store = createStore<RootState>({
    modules: {
        toasts: toastsModule,
    },
});

// Subscribe to store changes
store.subscribe((mutation, state) => {
    // Save the state to localStorage whenever there's a change
    localStorage.setItem('state', JSON.stringify(state));
});

// Initialize store state from localStorage if available
const savedState = localStorage.getItem('state');
if (savedState) {
    store.replaceState(JSON.parse(savedState));
}

export default store;
